import { ContentLayoutBox } from './ContentLayoutBoxV3/ContentLayoutBox'
import { CustomHead } from '../head'
import { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { GlobalLayout } from './styles'
import { HeapPageKey } from '@dtx-company/true-common/src/utils/heap/types'
import { ImpersonationBanner } from '@app/code/src/components/layout/impersonation-banner'
import { MOBILE_BOTTOM_NAV_BAR_HEIGHT } from '@dtx-company/true-common/src/constants/layout'
import { Meta } from '../../constants/meta'
import { ModalTypes } from '@dtx-company/inter-app/src/redux/slices/modal'
import { ProUpgradeModalProps } from '@app/code/src/components/ProUpradeModal'
import { RootCodeState, RootState } from '@dtx-company/inter-app/src/redux/types'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { UnauthSlot } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useCanonicalURL } from '@app/common/src/hooks/useCanonicalURL'
import { useCodeSelector } from '@dtx-company/inter-app/src/hooks/useCodeSelector'
import { useDemoModal } from '@app/common/src/hooks/useDemoModal/useDemoModal'
import { useDesktopV3SideDrawerState } from '../../containers/nav/side/v3/DesktopV3SideDrawer/hooks/useDesktopV3SideDrawerState'
import { useHeapScrollTracking } from '@dtx-company/inter-app/src/event-tracking/hooks/useHeapScrollTracking'
import { useHeapTranslateTracking } from '@dtx-company/inter-app/src/event-tracking/hooks/useHeapTranslateTracking'
import { useModal } from '@dtx-company/inter-app/src/redux/slices/utils'
import { useNavigationRoutes } from '../../hooks/useNavigationRoutes'
import { useNavigationV2 } from '@dtx-company/inter-app/src/hooks/useNavigation'
import { useProfilePictureModal } from '@dtx-company/inter-app/src/hooks/useProfilePictureModal'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { useShouldShowBottomMobileNavBar } from '@dtx-company/inter-app/src/hooks/useShouldShowBottomMobileNavBar/useShouldShowBottomMobileNavBar'
import { useShouldShowV3DesktopNavigation } from '@app/common/src/hooks/navv3/useShouldShowV3DesktopNavigation/useShouldShowV3DesktopNavigation'
import { useTrialBannerState } from '@app/code/src/components/Homepage/TrialBanner/useTrialBannerState'
import { useTrialIntroOverlayState } from '@app/common/src/components/TrialIntroOverlay/hooks/useTrialIntroOverlayState'
import dynamic from 'next/dynamic'
import events from '@dtx-company/inter-app/src/event-tracking/events/nav'

const Header = dynamic(() => import('./header').then(mod => mod.Header))

const CustomFooter = dynamic<{ customFooter: UnauthSlot }>(() =>
  import('./footer/minimal').then(mod => mod.CustomFooter)
)

const CreateFolderV2 = dynamic<Record<string, unknown>>(
  () =>
    import('@app/code/src/components/myflowcodes/CreateFolderV2/CreateFolderV2').then(
      mod => mod.CreateFolderV2
    ),
  {
    ssr: false
  }
)

const CreatePageContainer = dynamic<Record<string, unknown>>(
  () =>
    import('@app/page/src/components/FlowpageTemplates/CreatePage/CreatePageContainer').then(
      mod => mod.CreatePageContainer
    ),
  {
    ssr: false
  }
)

const Footer = dynamic<Record<string, unknown>>(() => import('./footer').then(mod => mod.Footer))

const MinimalFooter = dynamic<{ route?: string }>(() =>
  import('./footer/minimal').then(mod => mod.MinimalFooter)
)

const WhiteLabelFooter = dynamic(() =>
  import('./footer/WhiteLabelFooter/WhiteLabelFooter').then(mod => mod.WhiteLabelFooter)
)

const NotificationsWrapper = dynamic(() =>
  import('@dtx-company/inter-app/src/components/NotificationsWrapper').then(
    mod => mod.NotificationsWrapper
  )
)

const LeftNavigationRail = dynamic<Record<string, unknown>>(
  () =>
    import('../../containers/nav/side/v3/NavigationRail/NavigationRail').then(
      mod => mod.NavigationRail
    ),
  {
    ssr: false
  }
)

const ProfilePictureModal = dynamic<Record<string, unknown>>(
  () =>
    import('@app/common/src/components/ProfilePicture/ProfilePictureModal').then(
      mod => mod.ProfilePictureModal
    ),
  { ssr: false }
)

const LeftDesktopV3SideDrawer = dynamic<{
  open: boolean
  onMouseLeaveSideDrawer: () => void
  sideDrawerOpenViaHover: boolean
}>(
  () =>
    import('../../containers/nav/side/v3/DesktopV3SideDrawer/DesktopV3SideDrawer').then(
      mod => mod.DesktopV3SideDrawer
    ),
  {
    ssr: false
  }
)

const LeftSideDrawerToggle = dynamic<{
  drawerOpen: boolean
  onToggle: () => void
  openDrawerOnHover: () => void
}>(
  () =>
    import('../../containers/nav/side/v3/DesktopV3SideDrawer/components/SideDrawerToggle').then(
      mod => mod.SideDrawerToggle
    ),
  {
    ssr: false
  }
)

const DemoModal = dynamic(() =>
  import('@app/common/src/components/DemoModal').then(mod => mod.DemoModal)
)

const LockModal = dynamic(
  () => import('@app/common/src/components/LockModal').then(mod => mod.LockModal),
  { ssr: false }
)

const LockedFeatureModal = dynamic(
  () =>
    import('@app/common/src/components/LockedFeatureModal/LockedFeatureModal').then(
      mod => mod.LockedFeatureModal
    ),
  {
    ssr: false
  }
)

const ProUpgradeModal = dynamic<ProUpgradeModalProps>(
  () => import('@app/code/src/components/ProUpradeModal').then(mod => mod.ProUpgradeModal),
  {
    ssr: false
  }
)

const TrialIntroOverlay = dynamic(
  () =>
    import('@app/common/src/components/TrialIntroOverlay/TrialIntroOverlay').then(
      mod => mod.TrialIntroOverlay
    ),
  {
    ssr: false
  }
)

export interface ContentLayoutProps {
  children: ReactNode
  hideContentIfLoggedOut?: boolean
  isLeftMenuOpen: boolean
  showTopNav?: boolean
  showSideNav: boolean
  backgroundColor?: string | string[]
  opacity?: number
  shouldMountNavigationRail?: boolean
  shouldMountLeftSideDrawer?: boolean
  isV3DesktopSideDrawerOpen?: boolean
  isSideDrawerOpenViaHover: boolean
  hideSiteNavsForEditor?: boolean
  fillHeight?: boolean
  fillWidth?: boolean
}

export const ContentLayout: FC<ContentLayoutProps> = ({
  children,
  hideContentIfLoggedOut,
  showSideNav,
  backgroundColor,
  opacity,
  shouldMountNavigationRail,
  shouldMountLeftSideDrawer,
  isV3DesktopSideDrawerOpen,
  isSideDrawerOpenViaHover,
  hideSiteNavsForEditor,
  fillHeight,
  fillWidth
}) => {
  const displayV3Navigation = shouldMountNavigationRail || shouldMountLeftSideDrawer

  return (
    <ContentLayoutBox
      isV3DesktopSideDrawerOpen={isV3DesktopSideDrawerOpen}
      opacity={opacity}
      shouldMountNavigationRail={shouldMountNavigationRail}
      shouldMountLeftSideDrawer={shouldMountLeftSideDrawer}
      backgroundColor={backgroundColor}
      isSideDrawerOpenViaHover={isSideDrawerOpenViaHover}
      hideSiteNavsForEditor={hideSiteNavsForEditor}
      fillHeight={fillHeight}
      fillWidth={fillWidth}
      displayV3={displayV3Navigation || false}
      showSideNav={showSideNav}
    >
      {hideContentIfLoggedOut ? null : children}
    </ContentLayoutBox>
  )
}

// TODO refactor these condition props to all be positive, ie isSideNavEnabled instead of disableSideNav
export interface BaseContainerProps {
  children: ReactNode
  page?: string
  hideContentIfLoggedOut?: boolean
  backgroundColor?: string | string[]
  // TODO: enable header visibility - DF
  overrideIsHeaderVisible?: boolean
  injectedMeta?: Meta
  minimalFooter?: boolean
  disableTopNav?: boolean
  disableSideNav?: boolean
  disableFooter?: boolean
  overrideMobile?: boolean
  showOverflow?: boolean
  /**
   * Used to set a key indepdent of the page route that will be sent to heap during scroll tracking.
   */
  heapPageKey?: HeapPageKey
  whiteLabelFooter?: boolean
  customFooter?: UnauthSlot
  fillHeight?: boolean
  fillWidth?: boolean
}

// styling for all pages
export const BaseContainer = ({
  children,
  page,
  hideContentIfLoggedOut,
  injectedMeta,
  minimalFooter = false,
  backgroundColor,
  disableTopNav = false,
  disableSideNav = false,
  disableFooter = false,
  overrideMobile = false,
  heapPageKey,
  whiteLabelFooter,
  customFooter,
  showOverflow,
  fillHeight = false,
  fillWidth = false,
  overrideIsHeaderVisible = false
}: BaseContainerProps): JSX.Element => {
  const router = useRouter()
  const canonicalURL = useCanonicalURL()
  const { isAuthenticated, isAuthChecked } = useAuthState()
  const { platformNavHeight, navHeight, isSideNavBP } = useNavigationV2()
  const { shouldShowPlatformTopNav, shouldShowLeftNav } = useNavigationRoutes()
  const { isLeftMenuOpen } = useSelector((state: RootState) => state.appReducer.configReducer)
  const {
    modalOpen: proUpgradeModalOpen,
    setClose: setProUpgradeModalClose,
    props
  } = useModal(ModalTypes.PRO_UPGRADE_MODAL)
  const isCreateFolderModalOpen = useCodeSelector(
    (state: RootCodeState) => state.codeManagement.displayCreateFolderModal
  )
  const { modalOpen: isProfilePictureModal } = useProfilePictureModal()
  const { displayTrialBanner, bannerHeight } = useTrialBannerState()
  const shouldShowBottomNavBar = useShouldShowBottomMobileNavBar()
  const {
    shouldMountNavigationRail,
    shouldMountLeftSideDrawer,
    navigationRailMountShouldOverride,
    hideSiteNavsForEditor
  } = useShouldShowV3DesktopNavigation()
  const {
    isDesktopSideDrawerOpen,
    toggleSideDrawerOpen,
    setSideDrawerOpenViaHover,
    sideDrawerOpenViaHover
  } = useDesktopV3SideDrawerState()
  const { modalOpen: trialIntroOverlayOpen } = useTrialIntroOverlayState()
  const [everOpened, setEverOpened] = useState(false)
  useEffect(() => {
    if (trialIntroOverlayOpen) setEverOpened(true)
  }, [trialIntroOverlayOpen])
  // connect global tracking hooks. The same as for BaseEmptyContainer component
  useHeapScrollTracking(heapPageKey || page)
  useHeapTranslateTracking()
  const { modalOpen: isDemoModalOpen } = useDemoModal()

  useEffect(() => {
    if (document) {
      if (isLeftMenuOpen) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = ''
      }
      return function cleanup() {
        document.body.style.overflow = ''
      }
    }
  }, [isLeftMenuOpen])

  const proUpgradeModalProps = props as ProUpgradeModalProps | undefined

  const showFooter =
    (!shouldShowPlatformTopNav || router.pathname === Routes.FLOWPAGE || !isAuthenticated) &&
    !shouldShowLeftNav

  const showTopNav = useMemo(() => {
    if (overrideIsHeaderVisible) {
      return true
    } else if (isSideNavBP && !overrideMobile) {
      return true
    } else {
      return !disableTopNav
    }
  }, [overrideIsHeaderVisible, disableTopNav, overrideMobile, isSideNavBP])

  const isThereASideNavOrNavRail = !disableSideNav || navigationRailMountShouldOverride

  const mountV3NavigationRail =
    isThereASideNavOrNavRail &&
    !hideSiteNavsForEditor &&
    shouldMountNavigationRail &&
    isAuthenticated

  const mountDesktopSideDrawer =
    !disableSideNav && shouldMountLeftSideDrawer && !hideSiteNavsForEditor

  const shouldDisableTopNav = !showTopNav || hideSiteNavsForEditor

  return (
    <GlobalLayout
      disableTopNav={shouldDisableTopNav}
      navHeight={shouldShowPlatformTopNav ? platformNavHeight : navHeight}
      disableSideNav={disableSideNav}
      showOverflow={showOverflow}
      {...(shouldShowBottomNavBar && { marginBottom: MOBILE_BOTTOM_NAV_BAR_HEIGHT })}
      {...(displayTrialBanner && {
        marginTop: bannerHeight
      })}
    >
      <CustomHead
        injectedMeta={{
          canonical: canonicalURL,
          ...injectedMeta
        }}
        page={page}
      />
      {showTopNav && <Header />}
      <ImpersonationBanner />
      {mountV3NavigationRail && <LeftNavigationRail />}
      {mountDesktopSideDrawer && (
        <LeftDesktopV3SideDrawer
          open={isDesktopSideDrawerOpen}
          onMouseLeaveSideDrawer={() => setSideDrawerOpenViaHover(false)}
          sideDrawerOpenViaHover={sideDrawerOpenViaHover}
        />
      )}
      {mountDesktopSideDrawer && (
        <LeftSideDrawerToggle
          drawerOpen={isDesktopSideDrawerOpen}
          onToggle={toggleSideDrawerOpen}
          openDrawerOnHover={() => {
            setSideDrawerOpenViaHover(true)
            events.Platform_Desktop_LeftSideDrawer_Hovered_OpenDrawer()
          }}
        />
      )}

      <ContentLayout
        showTopNav={showTopNav}
        showSideNav={!disableSideNav}
        isLeftMenuOpen={isLeftMenuOpen}
        hideContentIfLoggedOut={hideContentIfLoggedOut && !isAuthenticated}
        backgroundColor={backgroundColor}
        shouldMountLeftSideDrawer={shouldMountLeftSideDrawer}
        shouldMountNavigationRail={shouldMountNavigationRail}
        isV3DesktopSideDrawerOpen={isDesktopSideDrawerOpen}
        isSideDrawerOpenViaHover={sideDrawerOpenViaHover}
        hideSiteNavsForEditor={hideSiteNavsForEditor}
        fillHeight={fillHeight}
        fillWidth={fillWidth}
      >
        {children}
      </ContentLayout>

      {!disableFooter && (
        <>
          {isAuthChecked && showFooter && !minimalFooter && !customFooter && !whiteLabelFooter && (
            <Footer disableSideNav={disableSideNav} />
          )}
          {minimalFooter && !customFooter && !whiteLabelFooter && (
            <MinimalFooter route={router.asPath} />
          )}
          {customFooter && <CustomFooter customFooter={customFooter} />}
          {whiteLabelFooter && !customFooter && <WhiteLabelFooter />}
        </>
      )}

      {isAuthenticated && (
        <>
          {isCreateFolderModalOpen && <CreateFolderV2 />}
          <CreatePageContainer />
          {isProfilePictureModal && <ProfilePictureModal />}
          <LockModal />
          <LockedFeatureModal />
          {everOpened && <TrialIntroOverlay />}
        </>
      )}
      {proUpgradeModalOpen && (
        <ProUpgradeModal
          modalOpen={proUpgradeModalOpen}
          onClose={() => {
            setProUpgradeModalClose()
            proUpgradeModalProps?.onClose?.()
          }}
        />
      )}
      {isDemoModalOpen && <DemoModal />}
    </GlobalLayout>
  )
}

interface PageContainerProps extends BaseContainerProps {
  /**
   * Notifications can be turned off to save on some downloaded JS if the page
   * will never use them.
   */
  disableNotifications?: boolean
}

export const PageContainer = ({
  disableNotifications = false,
  ...rest
}: PageContainerProps): JSX.Element => {
  return (
    <>
      {!disableNotifications && <NotificationsWrapper />}
      <BaseContainer {...rest} />
    </>
  )
}
