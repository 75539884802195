import { DemoModalVariants } from '@dtx-company/true-common/src/types/demoModal'
import { IthacaOperationName } from '@dtx-company/true-common/src/types/operations'
import {
  setDemoModalVariant,
  useDemoModalState
} from '@dtx-company/inter-app/src/redux/slices/demoModalSlice'
import { useDispatch } from 'react-redux'

export interface UseDemoModalReturnType {
  modalOpen: boolean
  onClose: () => void
  demoModalVariant?: DemoModalVariants
}

export interface UseOpenDemoModalArgs {
  demoModalVariant: DemoModalVariants
  ithacaOperationName: IthacaOperationName
  trackingCall?: () => void
  gatedAction: () => void
}

export interface OpenDemoModalReturn {
  handleProtectedActionClick: () => void
  isGateOpen: boolean
}

export const useDemoModal = (): UseDemoModalReturnType => {
  const dispatch = useDispatch()
  const { demoModalVariant } = useDemoModalState()
  const modalOpen = Boolean(demoModalVariant)

  const onClose = (): void => {
    dispatch(setDemoModalVariant())
  }

  return { onClose, modalOpen, demoModalVariant }
}
